<template>
  <BackgroundInsetWrapper tag="section" class="bg-purple" padding>
    <h2
      class="type-heading-1 mb-12 text-center md:mb-28"
      v-text="props.heading"
    />
    <div class="space-y-14 md:space-y-20">
      <div
        v-for="(item, index) in props.items"
        :key="index"
        :class="index % 2 ? 'pl-16 md:pl-0' : 'pr-16 md:pr-0'"
        class="relative"
      >
        <div v-if="item" class="grid-layout space-y-4 md:grid-cols-12">
          <div
            :class="
              index % 2
                ? 'md:order-2 xl:col-start-7 xl:col-end-12'
                : 'xl:col-start-2 xl:col-end-7'
            "
            class="relative md:col-span-6"
          >
            <div class="relative">
              <div
                class="aspect-square overflow-hidden rounded-[10px] md:aspect-[4/3]"
              >
                <PayloadImage
                  :image="item.image"
                  class="h-full w-full object-cover"
                  lazy
                />
              </div>
              <SpinStickerEarth
                v-if="index === 0"
                class="absolute bottom-0 right-0 -mr-16 mb-10 w-32 md:hidden"
              />
            </div>
            <SpinStickerEarth
              v-if="index === 1"
              class="absolute bottom-0 right-0 -mb-10 mr-5 hidden w-32 md:block lg:w-40 xl:-mr-20 xl:mb-10"
            />
          </div>
          <div
            :class="
              index % 2
                ? 'md:order-1 md:pr-5 xl:col-start-2 xl:col-end-6'
                : 'md:pl-5 xl:col-start-8 xl:col-end-12'
            "
            class="flex flex-col justify-center space-y-4 md:col-span-6 xl:px-0"
          >
            <h3
              class="type-heading-2 md:type-heading-3 lg:type-heading-2"
              v-text="item.heading"
            />
            <p class="type-body-md md:type-body-lg" v-text="item.text" />
          </div>
        </div>
        <CloudSwirl
          v-if="index !== 1"
          :class="
            index === 0
              ? 'left-0 top-0 -ml-24 -mt-10 w-64 rotate-[30deg] md:left-auto md:right-0 md:-mt-16 md:ml-0 md:w-52 lg:w-64 xl:mr-0 xl:w-72'
              : 'right-0 top-0 -mr-20 -mt-10 w-64 rotate-[190deg] md:bottom-0 md:right-0 md:top-auto md:-mb-10 md:-mr-8 md:w-52 md:rotate-[-30deg] lg:w-64 xl:mr-0 xl:w-72'
          "
          class="absolute"
          :type="index === 0 ? 2 : 4"
        />
      </div>
    </div>
  </BackgroundInsetWrapper>
</template>

<script lang="ts" setup>
import type { ImageHeadingTextArray } from '#payload/types'

interface AsymmetricImageTextSectionProps {
  heading: string
  items?: ImageHeadingTextArray
}

const props = withDefaults(defineProps<AsymmetricImageTextSectionProps>(), { items: () => [] })
</script>
