<template>
  <BaseSpinSticker>
    <template #text>
      <SvgRingTextPlanetFriendly class="h-full w-full" />
    </template>
    <template #illustration>
      <img
        src="/img/illustration-earth.png"
        class="h-full w-full object-contain"
      />
    </template>
  </BaseSpinSticker>
</template>
